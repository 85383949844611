import { ControlInterface } from './ControlInterface';

export class None extends ControlInterface {
    constructor() {
        super();
        this.type = ControlInterface.TYPE.NONE;
    }
    update() {
        super.update();
    }
}