import { Emulator } from './System/Emulator';

export class RetroBox {
	constructor() {
		this.emu = null;
		this.list = [];
		let self = this;

		$('body').append(RetroBox.Main);
		$('.header .menu .menu-item').click((e) => {
			$('.header .menu .menu-item').removeClass('active');
			$(e.target).addClass('active');
			self.load($(e.target).attr('ref'), $('.header .letter .letter-item.active').attr('ref'));
		});
		$('.header .letter .letter-item').click((e) => {
			$('.header .letter .letter-item').removeClass('active');
			$(e.target).addClass('active');
			self.load($('.header .menu .menu-item.active').attr('ref'), $(e.target).attr('ref'));
		});
		this.LoadingShow();
		this.load()
	}
	load(p, l) {
		let self = this;
		$.ajax({ data : { r : '_', p : p, l : l, } }).done((e) => {
			e = JSON.parse(e);
			self.list = e;
			$('.content').html('');
			for(let k in e){
			    var i = e[k];
			    var ii = $(`<div ref="${k}" class="content-item" onmouseover="$(this).find('img')[0].src='./img/rom/video/${i.p}.${i.n}.webp'" onmouseout="$(this).find('img')[0].src='./img/rom/img/${i.p}.${i.n}.png'" />`).append(
				$(`<img class="image" src="./img/rom/img/${i.p}.${e[k].n.replace("\\'", "'")}.png"/>`),
			        $(`<div class="title">${e[k].n.replace("\\'", "'")}</div>`)
			    );
			    $('.content').append(ii);
			}
			$('.content .content-item').click(function(e){
				if(self.emu == null) {
					console.warn('new Emulator');
					self.emu = new Emulator();
				}
				self.emu.loadGame(self.list[$(this).attr('ref')]);
			});
		});
	}
	LoadingShow() {
		$(RetroBox.LoadingFrame).appendTo('body');
	}
	LoadingHide() {
		$('.LoadingFrame').remove();
	}
}

RetroBox.Main = [
'<div class="content">',
'</div>',
'<div class="header">',
	'<div style="margin: 10px;">RetroBox</div>',
	'<div class="menu">',
		'<div ref="nes"          class="menu-item active">NINTENDO / DENDY</div>',
		'<div ref="snes"         class="menu-item">SUPER NINTENDO</div>',
		'<div ref="sega"         class="menu-item">SEGA / GENESIS</div>',
		'<div ref="gameboy"      class="menu-item">GAMEBOY</div>',
		'<div ref="gameboycolor" class="menu-item">GAMEBOY COLOR</div>',
	'</div>',
	'<div class="letter">',
		'<div ref="#" class="letter-item active">#</div>',
		(() => {
			let l = '';
			for(let k = 0; k < 25; k++) {
				let q = String.fromCharCode(65 + k);
				l += `<div ref="${q}" class="letter-item">${q}</div>`;
			}
			return l;
		})(),
	'</div>',	
'</div>',

].join('');

RetroBox.LoadingFrame = [
'<div class="LoadingFrame">',
	'<div class="title">Please wait</div>',
'<div>'
].join('');