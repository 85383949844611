import { ControlInterface } from './ControlInterface';

export class Network extends ControlInterface {
    constructor() {
        super();
        this.type = ControlInterface.TYPE.NETWORK;
    }
    update() {
        super.update();
    }
}