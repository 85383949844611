import { ControlInterface } from "./ControlInterface";
import { Keyboard } from "./Keyboard";
import { Joypad } from "./Joypad";
import { Network } from "./Network";
import { None } from "./None";

export class Player {
    constructor(data) {
        data = data || {};
        this.controls = data.parent || null;
        this.name = data.name || `Player_${new Date() * 1}`;
        this.number = data.number || ControlInterface.PLAYER.FIRST;
        this.Keyboard = new Keyboard(this);
        this.keySet = new Module.KeyBitset();
        this.Joypad = null;
    }

    setJoypad(n) {
        this.Joypad = n;
    }

    set enable(v) {
        this.Keyboard.enable = v;
    }

    update() {
        if(this.Joypad == null)
            this.Keyboard.update();
        else
            this.controls.Joypad.update( this );
    }
}