import { FpsCtrl } from './FPSControl';
import { SoundBuffer} from '../Audio/SoundBuffer';
import { Controls } from '../Controls/Controls';

export class Emulator {
	constructor() {
		this.nesBox = null;
		this.ready = false;
		this.actx = new AudioContext();
		this.toPNG = false;
		this.frameNumber = 0;
		this.controls = new Controls();
		//this.controls.show();
	}
	loadGame(url) {
		this.gd = url;
		url = this.gd.f;
		let self = this;
		try {
			Emulator.instance = this;
			if(this.nesBox == null || this.nesBox == undefined)
				this.nesBox = new Module.Nesbox();
		} catch(e) {
			console.warn('Не удалось запустить эмулятор!');
			this.closeGame();
			return;
		}
		$(Emulator.GameLoading).appendTo('body').hide().fadeIn();
		let request = new XMLHttpRequest();
		request.open("GET", url, true);
		request.responseType = "arraybuffer";
		request.onprogress = function(e) {
			$('.GameLoadingFrame').find('.progress').text(	`${Math.round(e.loaded / e.total) * 100}%` );
		}
		request.onload = function(e) {
			$('.GameLoadingFrame').fadeOut().hide().remove();
			if(request.response) {
                $(Emulator.VideoFrame).appendTo('body');//.fadeIn();
				$('.VideoFrame').find('.close').click(function(){ Emulator.instance.closeGame() });
				$('.VideoFrame').find('.controls').click(function(){ Emulator.instance.controls.show() });
				self.startGame(url, request.response);
				self.ready = true;
			} else {
				console.warn('Не удалось закрыть эмулятор!');
				this.closeGame();
				return;
			}
		}
		request.onerror = function(e) {
			$('.GameLoadingFrame').fadeOut().hide().remove();
			console.warn('Не удалось закрыть эмулятор!');
			this.closeGame();
			return;
		}
		request.send(null);
	}
	rgbSwap(buffer) {
		for(let k = 0; k < buffer.length / 4; k++) {
			let b = buffer[k * 4 + 0];
			let g = buffer[k * 4 + 1];
			let r = buffer[k * 4 + 2];
			let a = buffer[k * 4 + 3];
			buffer[k * 4 + 0] = r; buffer[k * 4 + 1] = g; buffer[k * 4 + 2] = b; buffer[k * 4 + 3] = 255;
		}
		return buffer;
	}
	startGame(url, rom) {
		var self = this;
		let byteArray = new Uint8Array(rom);
		let ba = new Module.VectorUnsignedChar();
		for (let i = 0; i < byteArray.length; i++) ba.push_back(byteArray[i]);
		this.soundBuffer = new SoundBuffer(this.actx);
		//this.one = new Module.KeyBitset();
		this.controls.enable = true;
		this.nesBox.LoadRom( url, ba );
		byteArray = null;
		ba.resize(0, 0);
		ba = null;
		this.ready = true;
		this.info = this.nesBox.GetRomInfo();
		this.Screen    = document.getElementById('Screen');
		this.Screen.width = this.info.width;
		this.Screen.height = this.info.height;
		this.ctx       = this.Screen.getContext('2d');
		this.imageData = this.ctx.getImageData(0, 0, this.info.width, this.info.height);
		//document.onkeydown = this.keysDown;
		//document.onkeyup = this.keysUp;
		//this.render();
		this.fc = new FpsCtrl(this.info.framerate, function(e) { self.render() });
		this.fc.start();
		
	}
	keysDown(code) {
		switch(code.code) {
			case "ArrowUp"    : Emulator.instance.one.SetKeyDown(Module.KeyType.UP);     break;
			case "ArrowDown"  : Emulator.instance.one.SetKeyDown(Module.KeyType.DOWN);   break;
			case "ArrowLeft"  : Emulator.instance.one.SetKeyDown(Module.KeyType.LEFT);   break;
			case "ArrowRight" : Emulator.instance.one.SetKeyDown(Module.KeyType.RIGHT);  break;
			case "KeyA"       : Emulator.instance.one.SetKeyDown(Module.KeyType.A);      break;
			case "KeyS"       : Emulator.instance.one.SetKeyDown(Module.KeyType.B);      break;
			case "KeyD"       : Emulator.instance.one.SetKeyDown(Module.KeyType.C);      break;
			case "Enter"      : Emulator.instance.one.SetKeyDown(Module.KeyType.START);  break;
			case "KeyZ"       : Emulator.instance.one.SetKeyDown(Module.KeyType.X);      break;
			case "KeyX"       : Emulator.instance.one.SetKeyDown(Module.KeyType.Y);      break;
			case "KeyC"       : Emulator.instance.one.SetKeyDown(Module.KeyType.Z);      break;
			case "ShiftRight" : Emulator.instance.one.SetKeyDown(Module.KeyType.MODE);   break;
		}
	}

	keysUp(code) {
		let self = Emulator.instance;
		//console.log(code.code);		
		switch(code.code) {
			case "F9"		  : self.toPNG = !0; self.pngNumber = 0; break;
			case "ArrowUp"    : Emulator.instance.one.SetKeyUp(Module.KeyType.UP);     break;
			case "ArrowDown"  : Emulator.instance.one.SetKeyUp(Module.KeyType.DOWN);   break;
			case "ArrowLeft"  : Emulator.instance.one.SetKeyUp(Module.KeyType.LEFT);   break;
			case "ArrowRight" : Emulator.instance.one.SetKeyUp(Module.KeyType.RIGHT);  break;
			case "KeyA"       : Emulator.instance.one.SetKeyUp(Module.KeyType.A);      break;
			case "KeyS"       : Emulator.instance.one.SetKeyUp(Module.KeyType.B);      break;
			case "KeyD"       : Emulator.instance.one.SetKeyUp(Module.KeyType.C);      break;
			case "Enter"      : Emulator.instance.one.SetKeyUp(Module.KeyType.START);  break;
			case "KeyZ"       : Emulator.instance.one.SetKeyUp(Module.KeyType.X);      break;
			case "KeyX"       : Emulator.instance.one.SetKeyUp(Module.KeyType.Y);      break;
			case "KeyC"       : Emulator.instance.one.SetKeyUp(Module.KeyType.Z);      break;
			case "ShiftRight" : Emulator.instance.one.SetKeyUp(Module.KeyType.MODE);   break;
		}
	}
	closeGame() {
		this.fc.pause();
		this.ready = false;
		this.Screen = null;
		this.nesBox.Release();
		$('.VideoFrame').remove();
		//document.onkeydown = null;
		//document.onkeyup = null;
		this.controls.enable = false;
		Emulator.instance = null;
	}
	render() {
		let self = Emulator.instance;
		if(!self.ready) return;
		self.controls.update();
		//console.log(self.frameNumber);
		//let m = self.nesBox.Video_One(self.one);
		let m = self.nesBox.Video_One(self.controls.PlayerOne.keySet);
		let ab = self.nesBox.Audio();
		let len = ab.length / 2;
		let nowBuffering = new Float32Array(len);
		for (let i = 0; i < len; i++) {
			nowBuffering[i]       = ab[i * 2 + 0] / (65536);
			//nowBuffering[i + len] = ab[i * 2 + 0] / (65536);
		}
		try { self.soundBuffer.addChunk(nowBuffering); } catch(e) { console.log(e) }
		self.imageData.data.set(m);
		self.ctx.putImageData(self.imageData, 0, 0);
		if(self.toPNG) {
			if(self.frameNumber % 2 == 1) {
				let png = $('#Screen')[0].toDataURL("image/png");
				let name = self.pngNumber + '';
				let len = name.length;
				for(let k = 0; k < 5 - len; k++) name = '0' + name;
				name += '.png';
				console.log(name);
				$.post('./screen.php',
					{
						gd  : JSON.stringify(self.gd),
						fn  : name,
						fr  : self.info.framerate,
						png : png,
					}
				)
				self.pngNumber++;
			}
			if(self.pngNumber == (self.info.framerate / 2) * 10) {
				self.toPNG = false;
				console.log(self.gd);
			}
		}
		self.frameNumber++;
		self.frameNumber %= self.info.framerate;
		//requestAnimationFrame( self.render );
	}
}

Emulator.instance = null;

Emulator.VideoFrame = [
'<div class="VideoFrame">',
	'<div class="Body">',
        //'<div class="Close" onclick="RETROBOX.Emulator.instance.closeGame()">CLOSE</div>',
		'<div class="close">CLOSE GAME</div>',
		'<div class="controls">CONTROLS</div>',
		'<canvas id="Screen" class="Screen"></canvas>',
	'</div>',
'<div>'
].join('');

Emulator.GameLoading = [
'<div class="GameLoadingFrame">',
	'<div class="title">Please wait</div>',
	'<div class="progress">10%</div>',
'<div>'	
].join('');