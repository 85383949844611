import { ControlInterface } from './ControlInterface';

export class Keyboard extends ControlInterface {
    constructor(parent) {
        super();
        this.parent = parent || null;
        this.type = ControlInterface.TYPE.KEYBOARD;
        console.log('Keyboard.EVENT.UP', Keyboard.EVENT.UP);
    }

    set keyMap(v) { this._map = v }
    get keyMap() { return this._map }
    
    set enable(v) {
        if(v == true) {
            document.addEventListener   ('keydown', { handleEvent : this.keyDown, self : this });
            document.addEventListener   ('keyup'  , { handleEvent : this.keyUp  , self : this });
        }
        else {
            document.removeEventListener('keydown', { handleEvent : this.keyDown, self : this });
            document.removeEventListener('keyup'  , { handleEvent : this.keyUp  , self : this });
        }
    }

    update() {
        super.update();
    }

    keyUp(event) { this.self.keyAction(event.code, Keyboard.EVENT.UP) }
    keyDown(event) { this.self.keyAction(event.code, Keyboard.EVENT.DOWN) }
    keyAction(key, action) {
        console.log(action, key);
        function setDown(e, v) { e.parent.keySet.SetKeyDown(v) }
        function setUp(e, v)   { e.parent.keySet.SetKeyUp(v) }
        let act = action == Keyboard.EVENT.DOWN ? setDown : setUp;
        let map = this.parent.number == ControlInterface.PLAYER.FIRST ? Keyboard.MAP.LEFTPOSITION : Keyboard.MAP.RIGHTPOSITION;
        switch(key) {
			case map.UP    : act(this, Module.KeyType.UP);     break;
			case map.DOWN  : act(this, Module.KeyType.DOWN);   break;
			case map.LEFT  : act(this, Module.KeyType.LEFT);   break;
			case map.RIGHT : act(this, Module.KeyType.RIGHT);  break;
			case map.A     : act(this, Module.KeyType.A);      break;
			case map.B     : act(this, Module.KeyType.B);      break;
			case map.C     : act(this, Module.KeyType.C);      break;
			case map.START : act(this, Module.KeyType.START);  break;
			case map.X     : act(this, Module.KeyType.X);      break;
			case map.Y     : act(this, Module.KeyType.Y);      break;
			case map.Z     : act(this, Module.KeyType.Z);      break;
			case map.MODE  : act(this, Module.KeyType.MODE);   break;
		}
    }
}

Keyboard.MAP = {
    LEFTPOSITION : {
        LEFT : 'KeyA', RIGHT : 'KeyD', UP : 'KeyW', DOWN : 'KeyS',
        X : 'KeyG', Y : 'KeyH', Z : 'KeyJ', A : 'KeyV', B : 'KeyB', C : 'KeyN',
        MODE : 'Space', START : 'Enter'
    },
    RIGHTPOSITION : {
        LEFT : 'ArrowLeft', RIGHT : 'ArrowRight', UP : 'ArrowTop', DOWN : 'ArrowDown',
        X : 'Numpad4', Y : 'Numpad5', Z : 'Numpad6', A : 'Numpad1', B : 'Numpad2', C : 'Numpad3',
        MODE : 'Space', START : 'Enter'
    },    
}

Keyboard.EVENT = {
    UP : 0,
    DOWN : 1
}