export class ControlInterface {
    constructor() {
        
    }

    get keys() { return this.keySet; }

    update() {}
}

ControlInterface.TYPE = {
    NONE     : 0,
    KEYBOARD : 1,
    JOYPAD   : 2,
    NETWORK  : 4
}

ControlInterface.PLAYER = {
    FIRST    : 0,
    SECOND   : 1
}