import { ControlInterface } from "./ControlInterface";
import { Player } from "./player";
import { Joypad } from "./Joypad";

export class Controls {
    constructor(data) {
        data = data || {};
        this.Joypad = new Joypad(this);
        this.PlayerOne = new Player({ number : ControlInterface.PLAYER.FIRST , parent : this });
        this.PlayerTwo = new Player({ number : ControlInterface.PLAYER.SECOND, parent : this });
        this.PlayerOne.setJoypad(0);
        this.gc = $(Controls.GameControl).hide();
        $('body').append(this.gc);
        var self = this;
        this.gc.find('.close').click(function(){ self.hide() });
    }
    
    set enable(v) { this.PlayerOne.enable = this.PlayerTwo.enable = v; }

    update() {
        this.PlayerOne.update();
        this.PlayerTwo.update();
    }
    show() { this.gc.show() }
    hide() { this.gc.hide() }
}

Controls.GameControl = [
    '<div class="GameControlFrame">',
        '<div class="close">CLOSE</div>',
    '</div>'	
].join('');

