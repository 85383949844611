import { ControlInterface } from './ControlInterface';

export class Joypad extends ControlInterface {
    constructor(parent) {
        super();
        this.haveEvents = 'GamepadEvent' in window;
        this.haveWebkitEvents = 'WebKitGamepadEvent' in window;
        this.timer = null;
        this.parent = parent || null;
        this.type = ControlInterface.TYPE.JOYPAD;
        this.controllers = [];
        this.enable = true;
    }
    set enable(v) {
        if(v == true) {
            if(this.haveEvents) {
                window.addEventListener('gamepadconnected'   , { handleEvent : this.addJoypad, self : this });
                window.addEventListener('gamepaddisconnected', { handleEvent : this.delJoypad, self : this });
            } else if(this.haveWebkitEvents) {
                window.addEventListener("webkitgamepadconnected"   ,  { handleEvent : this.addJoypad, self : this });
                window.addEventListener("webkitgamepaddisconnected",  { handleEvent : this.delJoypad, self : this });
            } else {
                this.timer = setInterval( this.scan, 500, this);
            }
        } else {
            if(this.haveEvents) {
                window.removeEventListener('gamepadconnected'   , { handleEvent : this.addJoypad, self : this });
                window.removeEventListener('gamepaddisconnected', { handleEvent : this.delJoypad, self : this });
            } else if(this.haveWebkitEvents) {
                window.removeEventListener("webkitgamepadconnected"   ,  { handleEvent : this.addJoypad, self : this });
                window.removeEventListener("webkitgamepaddisconnected",  { handleEvent : this.delJoypad, self : this });
            } else {
                clearInterval(this.timer);
                this.timer = null;
            }
        }
    }
    scan(self) {
        var gamepads = navigator.getGamepads ? navigator.getGamepads() : (navigator.webkitGetGamepads ? navigator.webkitGetGamepads() : []);
        for (var i = 0; i < gamepads.length; i++) {
            if (gamepads[i]) {
                if (!(gamepads[i].index in self.controllers)) {
                    self.addgamepad(gamepads[i]);
                } else {
                    self.controllers[gamepads[i].index] = gamepads[i];
                }
            }
        }
    }
    addJoypad(e) { console.log('add jp', e.gamepad); var gp = e.gamepad; this.self.controllers[gp.index] = gp }
    delJoypad(e) { console.log('del jp', e.gamepad); var gp = e.gamepad; delete this.self.controllers[gp.index] }

    update( player ) {
        super.update();
        this.scan(this);
        var jpn = player.Joypad;
        if(jpn == null) return;
        var jpo = this.controllers[jpn];
        if(jpo == null) return;
        var name = jpo.id.trim();
        name = name.replace(/\s\(.*\)/, '');
        name = name.replace(/^([\d\w]{4}\-){2}/ig, '');
        var sysname = name.trim()
        //sysname = sysname.replace(/(\s)|(\-)/ig, '_');
        //sysname = sysname.replace(/(\(R\)[\d]*)/ig, '');
        //sysname = sysname.toUpperCase();
        var map = Joypad.MAP[sysname];
        
        //console.log('-----------',name, sysname);
        //return;
        var AR, AL, AD, AU;
        for(var btn = 0; btn < jpo.buttons.length; btn++) {
            switch(map.btns[btn]) {
                case 'AU'    : AU = jpo.buttons[btn].pressed; break;
			    case 'AD'    : AD = jpo.buttons[btn].pressed; break;
			    case 'AL'    : AL = jpo.buttons[btn].pressed; break;
			    case 'AR'    : AR = jpo.buttons[btn].pressed; break;
                default : this.keyAction(map.btns[btn], jpo.buttons[btn].pressed, player);
            }
        }
        for(var axes = 0; axes < jpo.axes.length; axes++) {
            //console.log(map.axis[axes], jpo.axes[axes]);
            switch(map.axis[axes]) {
                case 'ALR'  :
                case 'LALR' : AL |= (jpo.axes[axes] < 0); AR |= (jpo.axes[axes] > 0); break;
                case 'AUD'  :
			    case 'LAUD' : AU |= (jpo.axes[axes] < 0); AD |= (jpo.axes[axes] > 0); break;			    
            }
        }
        //AR = AR == 1; AL = AL == 1;
        //AD = AD == 1; AU = AU == 1;
        //console.log(AR, AL, AD, AU);
        this.keyAction('AR', AR, player);
        this.keyAction('AL', AL, player);
        this.keyAction('AD', AD, player);
        this.keyAction('AU', AU, player);
    }
    keyAction(key, action, player) {
        function setDown(e, v) { e.keySet.SetKeyDown(v) }
        function setUp(e, v)   { e.keySet.SetKeyUp(v) }
        let act = action == Joypad.EVENT.DOWN ? setDown : setUp;
        switch(key) {
			case 'AU'    : act(player, Module.KeyType.UP);     break;
			case 'AD'    : act(player, Module.KeyType.DOWN);   break;
			case 'AL'    : act(player, Module.KeyType.LEFT);   break;
			case 'AR'    : act(player, Module.KeyType.RIGHT);  break;
			case 'A'     : act(player, Module.KeyType.A);      break;
			case 'B'     : act(player, Module.KeyType.B);      break;
			case 'RT'    : act(player, Module.KeyType.C);      break;
			case 'START' : act(player, Module.KeyType.START);  break;
			case 'X'     : act(player, Module.KeyType.X);      break;
			case 'Y'     : act(player, Module.KeyType.Y);      break;
			case 'LT'    : act(player, Module.KeyType.Z);      break;
            case 'BACK'  : act(player, Module.KeyType.MODE);   break;
            case 'LB'    : act(player, Module.KeyType.L);      break;
            case 'RB'    : act(player, Module.KeyType.R);      break;
		}
    }
}

Joypad.MAP = {}
Joypad.MAP["Microsoft X-Box 360 pad"] = {
    btns : [ 'A', 'B', 'X', 'Y', 'LB', 'RB', 'LT', 'RT', 'BACK', 'START', 'LA', 'RA', 'AU', 'AD', 'AL', 'AR', 'HOME' ],
    axis : [ 'LALR', 'LAUD', 'RALR', 'RAUD' ]
};
Joypad.MAP["Sony PLAYSTATION(R)3 Controller"] = Joypad.MAP.MICROSOFT_X_BOX_360_PAD;
Joypad.MAP.USB_GAMEPAD = {
    btns : [ 'Y', 'B', 'A', 'X', 'LB', 'RB', 'LT', 'RT', 'BACK', 'START', 'LA', 'RA' ],
    axis : [ 'LALR', 'LAUD', 'RALR', 'RAUD', 'ALR', 'AUD']
};
Joypad.MAP.USB_BFM_GAMEPAD = {
    btns : [ 'A', 'B', '', 'X', 'Y', '', 'LB', 'RB', 'LT', 'RT', 'BACK', 'START', 'HOME', 'LA', 'RA' ],
    axis : [ 'LALR', 'LAUD', 'RALR', 'RAUD', 'RT', 'LT', 'ALR', 'AUD']
};

Joypad.EVENT = {
    UP : false,
    DOWN : true
}